<template>

  <div id="footer">

    <div class="footer-content">

        <img src="../assets/Logo.svg" alt="Logopedie Lara Gasparini Logo" id="big-logo">

        <div class="footer-text">

            <div class="footer-text-block" id="footer-title">
                <p><strong>Logopedische Praktijk</strong></p>
                <p>Lara Gasparini</p>
            </div>

            <div class="footer-text-block">
                <p>Borggravevijversstraat 34</p>
                <p>3500 Hasselt</p>
            </div>

            <div class="footer-text-block">
                <p>0494/93.21.95</p>
                <a href="mailto:Lara_Gasparini@outlook.be">lara_gasparini@outlook.be</a>
            </div>
            
        </div>

    </div>

    <img src="../assets/Footer-Illustration.png" id="footer-illustration">

  </div>

</template>

<script>
export default {

}
</script>

<style>
#footer{

    background-color: white;

}

.footer-content{
    display: flex;
    flex-direction: column;
    gap: 64px;

    justify-content: center;
    align-items: center;

    padding: 160px 0 80px 0;
}

.footer-text{
    display: flex;
    flex-direction: column;
    gap: 40px;

    align-items: center;

    width: 370px;

    font-size: 25px;
    color: #4A4446;
}

.footer-text #footer-title p{
    font-size: 31px;
    color: #201D1E;

    font-weight: 500;
}

.footer-text #footer-title p strong{
    font-weight: 700;
}

.footer-text-block{
    display: flex;
    flex-direction: column;

    align-items: center;

    gap: 4px;
}

#big-logo{
    height: 231px;
}

#footer-illustration{
    position: relative;
    width: 100%;

    bottom: -5px;
}

#background-bleed{
    position: relative;
    bottom: 0px;

    height: 200px;

    background-color: #EE3965;

}

@media screen and (max-width: 600px){

    .footer-content{
        gap: 40px;

        padding: 120px 0 156px 0;
    }

    .footer-text{
        width: 100%;

        font-size: 20px;
    }

    .footer-text #footer-title p{
        font-size: 25px;
    }

    #big-logo{
        height: 144px;
    }

}
</style>