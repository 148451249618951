<template>
  <div class="section even" id="Behandelingen">

    <div class="body-content">
        <SectionTitle text="Behandelingen"/>

        <div class="flyouts">

            <Behandeling title="Leerstoornis" id="leerstoornissen" :class="{ active: showLeer }" @click="toggleBehandelingen('leerstoornissen','toggle')">

                <div class="behandeling-description">
                    <p>Men spreekt van een leerstoornis wanneer men moeilijkheden heeft met het leren van schoolse vaardigheden (lezen, rekenen en spellen). Die problemen hebben geen aanwijsbare onderliggende oorzaak, hoewel er hoogstwaarschijnlijk een erfelijke factor meespeelt. Dat wil dus zeggen dat men een normale intelligentie heeft. Een ander kenmerk is de hardnekkigheid van deze stoornissen. Ondanks dat er veel geoefend wordt, blijft er een achterstand bestaan en zijn deze stoornissen dus niet van voorbijgaande aard.</p>
                </div>
                
            </Behandeling>

            <Behandeling title="Taalontwikkelingsstoornis" id="taalontwikkelingsstoornissen" :class="{ active: showTaal }" @click="toggleBehandelingen('taalontwikkelingsstoornissen','toggle')">

            <div class="behandeling-description">
                <p>Wanneer de taalontwikkeling van een kind trager of anders verloopt dan een leeftijdsgenoot, zonder duidelijke oorzaak, kan er een taalstoornis aanwezig zijn.</p>
                <p>Enkele kenmerken zijn: een beperkte woordenschat, laat beginnen praten, woordvindingsmoeilijkheden, een foutieve zinsbouw en onjuiste verbuigingen van (werk)woorden.</p>
                <p>Het kan ook zijn dat het taalbegrip is aangetast, maar dat is meestal wel beter dan het spreken zelf.</p>
            </div>

            </Behandeling>

            <Behandeling title="Articulatiestoornis" id="articulatiestoornissen" :class="{ active: showArtic }" @click="toggleBehandelingen('articulatiestoornissen','toggle')">

                <div class="behandeling-description">
                    <p>Een persoon met een articulatieprobleem spreekt klanken of woorden verkeerd uit. Er kan sprake zijn van een fonetisch of een fonologisch probleem.</p>
                    <p>Bij een fonetisch probleem worden één of meerdere klanken verkeerd uitgesproken.</p>
                    <p>Bij het laatstgenoemde probleem worden de klanken meestal wel geïsoleerd correct uitgesproken, maar worden ze verkeerd gezegd in combinatie met andere klanken. De fouten zijn inconsistent.</p>
                </div>

            </Behandeling>

            <Behandeling title="Myofunctionele Stoornis (OMFT)" id="OMFT" :class="{ active: showOMFT }" @click="toggleBehandelingen('OMFT','toggle')">

            <div class="behandeling-description">
                <p>Oromyofunctionele therapie is gericht op het herstellen van een verstoord evenwicht in het functioneren van de spieren in en om de mond.</p>
                <p>Wanneer bepaalde spieren niet goed functioneren heeft dit een effect op de vorm van het gebit en/of de kaken. Er zijn een aantal afwijkende mondgewoonten die dan afgeleerd dienen te worden, zoals infantiel slikken, verkeerde rustpositie van de tong, zuiggewoonten en mondademen. De spraak is meestal ook verstoord. Zo wordt er vaak met de tong tegen of tussen de tanden gesproken.</p>
                <p>Het doel van OMFT is de mondspieren terug in evenwicht brengen en om verkeerde reflexen af te leren. Daardoor wordt voorkomen dat, onder andere, de tanden terug gaan beginnen verschuiven na een orthodontische behandeling.</p>
            </div>

            </Behandeling>

            <Behandeling title="Afasie" id="afasie" :class="{ active: showAfasie }" @click="toggleBehandelingen('afasie','toggle')">

            <div class="behandeling-description">
                <p>Afasie is een verworven taalstoornis die wordt veroorzaakt door een hersenbloeding of een herseninfarct. Het spreken, begrijpen, lezen en schrijven worden verstoord. Belangrijk om te weten is dat de articulatie van woorden en de algemene intelligentie hierdoor niet zijn aangetast.</p>
                <p>Binnen de afasie zijn er verschillende vormen en ernstgraden. Zo kan de ene persoon meer moeilijkheden hebben met het begrijpen van taal en iemand anders kan dan weer meer moeite hebben met het spreken.</p>
                <p>Afasie brengt mogelijk andere problemen met zich mee zoals aandachts- en concentratiestoornissen, vermoeidheid en andere of intensere emotionele reacties. Het herstel is erg individualistisch bepaald en hangt af van verschillende factoren, zoals leeftijd en de ernst van de afasie.</p>
            </div>

            </Behandeling>

            <Behandeling title="Dysartrie" id="dysartrie" :class="{ active: showDysartrie }" @click="toggleBehandelingen('dysartrie','toggle')">

            <div class="behandeling-description">
                <p>Dysartrie is een spraakstoornis waarbij de patiënt een onduidelijke, verstoorde uitspraak heeft. De oorzaak is een beschadiging van het zenuwstelsel.</p>
                <p>Men heeft een slechte controle over de spieren die verantwoordelijk zijn voor de spraak, de ademhaling en de stemgeving. Daarnaast kunnen het slikken en het kauwen gestoord zijn.</p>
                <p>Ook kan de patiënt last hebben van een schorre of hese stem.</p>
            </div>

            </Behandeling>

        </div>

    </div>

  </div>
</template>

<script>
import { ref } from 'vue'

import SectionTitle from './blocks/SectionTitle.vue'
import Behandeling from './blocks/Behandeling.vue'

export default {
    components: {
        SectionTitle,
        Behandeling
  },
  setup(){
    const showLeer = ref(false);
    const showArtic = ref(false);
    const showTaal = ref(false);
    const showOMFT = ref(false);
    const showAfasie = ref(false);
    const showDysartrie = ref(false);
    
    return { showLeer, showArtic, showTaal, showOMFT, showAfasie, showDysartrie };
  },
  methods: {
    toggleBehandelingen(behandeling, mode){

        if(mode === "expand"){
            this.showLeer = false;
            this.showArtic = false;
            this.showTaal = false;
            this.showOMFT = false;
            this.showAfasie = false;
            this.showDysartrie = false;
        }

        if(behandeling === 'leerstoornissen'){

            if(mode === 'expand'){
                this.showLeer = true;
            } else{
                this.showLeer = !this.showLeer;
            }

        }

        if(behandeling === 'articulatiestoornissen'){

            if(mode === 'expand'){
                this.showArtic = true;
            } else{
                this.showArtic = !this.showArtic;
            }

        }

        if(behandeling === 'taalontwikkelingsstoornissen'){

            if(mode === 'expand'){
                this.showTaal = true;
            } else{
                this.showTaal = !this.showTaal;
            }

        }

        if(behandeling === 'OMFT'){

            if(mode === 'expand'){
                this.showOMFT = true;
            } else{
                this.showOMFT = !this.showOMFT;
            }

        }

        if(behandeling === 'afasie'){

            if(mode === 'expand'){
                this.showAfasie = true;
            } else{
                this.showAfasie = !this.showAfasie;
            }

        }

        if(behandeling === 'dysartrie'){

            if(mode === 'expand'){
                this.showDysartrie = true;
            } else{
                this.showDysartrie = !this.showDysartrie;
            }

            }
        

    }
  }
}
</script>

<style>
#Behandelingen .body-content{
    padding-top: 168px;
    padding-bottom: 80px;
}

#Behandelingen .title-group{
    margin-bottom: 64px;
}

.flyouts{
    display: flex;
    flex-direction: column;
    gap: 16px;
}

@media screen and (max-width: 600px){

    #Behandelingen .body-content{
        padding-top: 64px;
        padding-bottom: 120px;
    }

}
</style>