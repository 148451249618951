<template>
  <div class="section even" id="wie-ben-ik">

    <div class="card">
        <SectionTitle text="Wie ben ik"/>
        <p>In 2012 behaalde ik mijn masterdiploma in de <strong>Logopedische en Audiologische Wetenschappen</strong> aan de KU Leuven.</p>
        <p>Ik wilde graag nog meer praktijkervaring opdoen en besloot om nog een jaar <strong>Zorgverbreding en Remediërend Leren</strong> bij te studeren aan de Katholieke Hogeschool Limburg.</p>
        <p>Sinds 2013 ben ik actief als zelfstandige logopediste en heb ik <strong>ruime ervaring</strong> opgedaan in logopedische praktijken en scholen.</p>
        
        <div class="logos">
            <img src="../assets/KU-Leuven.png" alt="KU Leuven Logo">
            <img src="../assets/UCI-Minds.png" alt="UCI Minds Logo">
        </div>
    </div>

  </div>
</template>

<script>
import SectionTitle from './blocks/SectionTitle.vue'

export default {
    components: {
        SectionTitle
  }
}
</script>

<style>
#wie-ben-ik{
    min-height: max(100vh, 777px);
}
#wie-ben-ik h1{
    font-size: 40px;
}

.card{
    position: absolute;

    padding: 72px 120px 48px 120px;

    left: calc(50% - 1003px/2 );
    top: calc(50% - 617px/2 );

    background: #FFF;
    box-shadow: 0px 4px 16px 4px rgba(35, 5, 13, 0.12);
}

.card p{
    width: 763px;

    margin-bottom: 24px;
}

.card strong{
    font-weight: 500;
}

.logos{
    display: flex;
    gap: 48px;

    justify-content: center;

    margin-top: 64px;
}

@media screen and (max-width: 600px){

    #wie-ben-ik h1{
        font-size: 25px;
    }

    .card{
        position: static;
        padding: 48px 24px;

        width: calc(100vw - 96px);

        margin: 48px 24px;

        min-height: auto;
    }

    .card p {
        width: auto;
    }

    .logos{
        flex-direction: column;
        align-items: center;
        gap: 16px;

        width: 100%;
        margin-top: 16px;
    }

    .logos img{
        width: 92px;
    }

}
</style>