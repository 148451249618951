<template>
  <div class="table">

    <div class="labels">

        <div class="label" id="lb_tarief">
            <p>Tarief</p>
        </div>

        <div class="label" id="lb_verplichte">
            <div class="lb_heading">
                <p>Verplichte verzekering</p>
            </div>
            <div class="lb_sub">
                <p>Terugbetaling</p>
                <p>remgeld</p>
            </div>
        </div>

        <div class="label" id="lb_verhoogde">
            <div class="lb_heading">
                <p>verhoogde tegemoetkoming</p>
            </div>
            <div class="lb_sub">
                <p>Terugbetaling</p>
                <p>remgeld</p>
            </div>
        </div>

    </div>

    <div class="rows">
        <Table_Row title="Bilan" subtitle="30 minuten" tarief="€ 42,75" vp_terug="€ 35,25" vp_rem="€ 7,50" ver_terug="€ 39,75" ver_rem="€ 3,00"/>
        <Table_Row title="Evolutiebilan" tarief="€ 51,50" vp_terug="€ 40,50" vp_rem="€ 11,00" ver_terug="€ 47,00" ver_rem="€ 4,50" class="even"/>
        <Table_Row title="Sessie" subtitle="30 minuten" tarief="€ 36,14" vp_terug="€ 30,64" vp_rem="€ 5,50" ver_terug="€ 34,14" ver_rem="€ 2,00"/>
        <Table_Row title="Sessie" subtitle="60 minuten" tarief="€ 72,59" vp_terug="€ 61,59" vp_rem="€ 11,00" ver_terug="€ 68,09" ver_rem="€ 4,50" class="even"/>
    </div>

  </div>
</template>

<script>
import Table_Row from './Table-Row.vue'

export default {
    components: {
        Table_Row
  }
}
</script>

<style>
.table{
    max-width: 1158px;

    color: #4A4446;
}

.labels{
    display: flex;
    align-items: end;

    padding-left: 244px;
    margin-bottom: 8px;
}

.labels p{
    font-size: 16px;
    line-height: 16px;
    letter-spacing: 0.48px;
    text-transform: uppercase;

    color: #4A4446;

    width: 158px;
}

.lb_heading{
    margin-bottom: 12px;
}

.lb_heading p{
    width: 255px;

    font-weight: 500;

    color: #201D1E;
}

.lb_sub{
    display: flex;
}

#lb_tarief{
    min-width: 210px;
}

#lb_verplichte{
    min-width: 374px;
}

@media screen and (max-width: 600px){

    .labels{
        display: none;
    }

}
</style>