<template>
    <div class="section" id="Werkwijze">

        <div class="body-content">

            <SectionTitle text="Werkwijze"/>

            <div class="steps">
                <WerkStap count="1" title="Intake">
                    <div class="text-block">
                        <p>We plannen eerst een <strong>intakegesprek</strong> in, waarbij ik de specifieke hulpvraag beter in kaart kan brengen door een gesprek en de nodige testafnames. Op basis daarvan wordt een therapieplan opgesteld. </p>
                    </div>
                </WerkStap>

                <WerkStap count="2" title="Therapie">
                    <p>We voeren het <strong>therapieplan</strong> uit, dat is aangepast aan de individuele noden van de patiënt. Ik neem je persoonlijke achtergrond mee in de therapie, en beperk me niet tot enkel de hulpvraag. Zo besteed ik, bijvoorbeeld, bij kinderen met een leerstoornis extra aandacht aan zelfvertrouwen en zelfstandigheid.  </p>
                </WerkStap>

                <p>Aarzel niet om mij te <a href="#Contact">contacteren</a> voor bijkomende vragen.</p>

            </div>

        </div>

        <img src="../assets/Path.svg" class="path">

    </div>
</template>

<script>
import SectionTitle from './blocks/SectionTitle.vue'
import WerkStap from './blocks/WerkStap.vue'

export default {
    components: {
        SectionTitle,
        WerkStap
  }
}
</script>

<style>
#Werkwijze .body-content{
    padding-top: 168px;
    padding-bottom: 80px;
}

#Werkwijze .title-group{
    margin-bottom: 64px;
}

.steps{
    display: flex;
    flex-direction: column;
    gap: 64px;

    margin-top: 64px;
}

.text-block{
    display: flex;
    flex-direction: column;
    gap: 24px;
}

.path{
    position: absolute;
    left: 27px;
    top: 0px;

    height: 100%;
}

@media screen and (max-width: 600px){

    #Werkwijze .body-content{
        padding-top: 64px;
        padding-bottom: 120px;
    }

    .path{
        display: none;
    }

    .steps{
        gap: 48px;
    }

}

</style>