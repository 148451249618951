<template>
  <div class="row">
    
    <div class="row-content" id="row-title">

        <div class="maintitle">
            {{ title }}
        </div>

        <div class="subtitle">
            {{ subtitle }}
        </div>

    </div>

    <div class="row-content" id="tarief">

        <p class="amount">{{ tarief }}</p>

    </div>

    <div class="row-content" id="verplichte">

        <p class="amount">{{ vp_terug }}</p>
        <p class="amount">{{ vp_rem }}</p>

    </div>

    <div class="row-content" id="verhoogde">

        <p class="amount">{{ ver_terug }}</p>
        <p class="amount">{{ ver_rem }}</p>

    </div>

  </div>

  <div class="row-mobile">

    <div class="title-group">
        <div class="left">{{ title }}</div>
        <div class="right">Tarief: <b>{{ tarief }}</b></div>
    </div>
    {{ subtitle }}

    <div class="row-content verplichte">
        <div class="row-title">Verplichte Verzekering</div>
        <div class="terug">Terugbetaling <b>{{ vp_terug }}</b></div>
        <div class="rem">Remgeld <b>{{ vp_rem }}</b></div>
    </div>

    <div class="row-content verhoogde">
        <div class="row-title">Verhoogde Tegemoetkoming</div>
        <div class="terug">Terugbetaling <b>{{ ver_terug }}</b></div>
        <div class="rem">Remgeld <b>{{ ver_rem }}</b></div>
    </div>
  </div>

</template>

<script>
export default {
    props: ['title', 'subtitle', 'tarief', 'vp_terug', 'vp_rem', 'ver_terug', 'ver_rem']
}
</script>

<style>

.row{
    display: flex;
    align-items: center;

    width: 100%;
    height: 96px;

    padding-left: 48px;
}

.row-mobile{
    display: none;
}

.row.even{
    background-color: #FFFAFB;
}

.row-content{
    display: flex;
}

.row-content#row-title{
    flex-direction: column;
    gap: 0;

    min-width: 196px;
}

.row-content#tarief{
    min-width: 210px;
}

.row-content#verplichte{
    min-width: 374px;
}

.maintitle{
    font-size: 25px;
    font-weight: 500;

    color: #201D1E;
}

.subtitle{
    font-size: 16px;
}

.amount{
    font-size: 20px;
    min-width: 158px;

    letter-spacing: 1px;
}

@media screen and (max-width: 600px){
    
    .row{
        display: none;
    }

    .row-mobile{
        display: flex;
        flex-direction: column;

        padding: 32px 0 40px 0;

        width: 100%;
    }

    .row-mobile.zebra{
        background-color: #FFF4F6;
    }

    .row-content{
        display: block;
    }

    .row-title{
        font-size: 20px;
        font-weight: 500;

        margin-bottom: 2px;
    }

    .terug{
        margin-bottom: 2px;
    }

    #Tarieven .row-mobile .title-group{
        display: flex;
        align-items: center;

        margin-bottom: 2px;

        width: 100%;
    }

    .verplichte{
        margin-top: 16px;
    }

    .verhoogde{
        margin-top: 24px;
    }

    .left{
        font-size: 25px;
        font-weight: 500;
    }

    .right{
        margin-left: auto;
    }

    .right b{
        font-weight: 500;
        margin-left: 8px;
    }

}

</style>