<template>
  <div id="menu" ref="menu">

    <div class="mobile-menu">

        <a href="#footer" class="practice-info">

            <div class="logo">
                <img src="../assets/Logo.svg" />
            </div>
            <div class="info">
                <strong>Logopedische Praktijk</strong>
                Lara Gasparini
            </div>
        </a>

            <div class="mobile-action" @click="toggleMenu">
                <div class="grey-diamond">
                    <img src="../assets/HamburgerMenu.svg">
                </div>
            </div>

    </div>
   
    
    <div class="menu-links">

        <a href="#" class="menu-item" @click="toggleMenu">
            Welkom
        </a>

        <a href="#wie-ben-ik" class="menu-item" @click="toggleMenu">
            Wie ben ik
        </a>

        <a href="#Werkwijze" class="menu-item" @click="toggleMenu">
            Werkwijze
        </a>
        
        <a href="#Behandelingen" class="menu-item" @click="toggleMenu">
            Behandelingen
        </a>
       
        <a href="#Tarieven" class="menu-item" @click="toggleMenu">
            Tarieven
        </a>
        
        <a href="#Contact" class="menu-item" @click="toggleMenu">
            Contact
        </a>
        

    </div>

        <div class="call-to-action">
            <a href="#Contact">
                <button class="small" @click="toggleMenu">Contacteer mij</button>
            </a>
        </div>
    
  </div>
</template>

<script>
import { ref } from 'vue'

export default {
    setup(){
        const menu = ref(null)

        const toggleMenu = () => {
            menu.value.classList.toggle('expanded');
        }

        return { toggleMenu, menu }
    }

}
</script>

<style>
#menu{
    position: sticky;
    display: flex;
    left: 0;
    top: 0;
    align-items: center;

    width: calc(100vw - 128px);
    height: 112px;
    z-index: 10;

    background-color: white;

    box-shadow: 0px 4px 6px 3px rgba(35, 5, 13, 0.02);

    padding: 0 64px 0 64px;
}

.practice-info{
    display: flex;
    align-items: center;

    margin-right: auto;
}

.info {
    display: flex;
    flex-direction: column;

    font-size: 20px;
    line-height: 20px;

    margin-left: 16px;

    color: #4A4446;
}

.info strong{
    margin-bottom: 4px;

    font-weight: 500;
}

.mobile-menu{
    margin-right: auto;
}

.mobile-action{
    display: none;
}

.menu-links{

    left: calc(50% - 408px);

    display: flex;
    flex-direction: row;
}

.menu-item{
    font-weight: 400;
    font-style: normal;
    font-size: 22px;
    color: #685F62;

    margin-right: 56px;

    position: relative;
}

.menu-item:last-child{
    margin: 0;
}

.menu-item#active{
    font-weight: 600;

    color: #EE3965;
}

.menu-item#active::after{
    content: url(../assets/Underline-Menu.svg);

    position: absolute;

    left: -2px;
    bottom: -12px;

}

.call-to-action{
    margin-left: auto;
}

@media screen and (max-width: 600px){
    #menu{
        height: 100px;
        width: calc(100vw - 48px);

        padding: 0px 24px;
    }

    #menu.expanded{
        height: 100vh;
        padding-top: 10px;

        align-items: normal;

        flex-direction: column;
    }

    .info{
        font-size: 16px;
    }

    .info strong{
        margin-bottom: 0px;
    }

    .mobile-action{
        display: block;
    }

    .mobile-menu{
    display: flex;
    align-items: center;

    width: 100%;
    }

    #menu.expanded .mobile-menu{
        height: 48px;
        margin-top: 16px;
    }

    .grey-diamond img{
        position: relative;
        top: 16px;
        left: 15px;

        z-index: 1;

        transform: rotate(135deg);
    }

    .grey-diamond{

        width: 48px;
        height: 48px;

        transform: rotate(45deg);

        background-color: #FFF4F7;
    }

    
    .menu-links{
        display: none;
    }

    #menu.expanded .menu-links{
        display: flex;
        flex-direction: column;
        gap: 56px;

        margin-top: 64px;
        margin-bottom: auto;

        justify-content: center;
        align-items: center;

        font-size: 25px;
    }

    #menu.expanded .menu-item{
        margin-right: 0;
    }

    .call-to-action{
        display: none;

        margin-bottom: 40px;

        width: 100%;
    }

    #menu.expanded .call-to-action{
        display: block;
    }
}

</style>