<template>

    <div class="section even" id="Contact">

        <div class="body-content">

            <SectionTitle text="Contact"/>

            <div class="columns">

                <div class="column" id="form">

                    <p>Laat mij weten <strong>voor wie</strong> u contact opneemt, wat de <strong>problematiek</strong> is en op welk <strong>telefoonnummer</strong> ik u kan bereiken. Ik zal u dan zo snel mogelijk telefonisch contacteren.</p>

                    <div class="form" @submit.prevent="sendEmail()">
                        <form id="contactForm">
                            <label>NAAM*</label>
                            <input type="text" required v-model="name">

                            <label>E-MAILADRES*</label>
                            <input type="email" required v-model="email">

                            <label>TELEFOONNUMMER*</label>
                            <input type="number" required v-model="phone_number">

                            <label>BERICHT*</label>
                            <textarea required v-model="message"></textarea>

                            <input type="submit" id="button" value="Verstuur Bericht">
                            <p id="messageSent">Bericht gestuurd</p>
                        </form>
                    </div>

                </div>

                <div class="column" id="gMap">

                    <div class="map-card">

                        <p>Locatie</p>

                        <a href="https://www.google.com/maps/place/Logopedie+Lara+Gasparini/@50.9470113,5.3766228,17z/data=!4m15!1m8!3m7!1s0x47c12119ec57665d:0x59ee6e596b2f7893!2sBorggravevijversstraat+34,+3500+Hasselt!3b1!8m2!3d50.9470113!4d5.3766228!16s%2Fg%2F11c4d6l591!3m5!1s0x47c1215cf9120f67:0xbe35b6184e8915c1!8m2!3d50.9470781!4d5.3765725!16s%2Fg%2F11nmmwqzr6?entry=ttu">
                            <img src="../assets/GoogleMaps.png" alt="Google Maps">
                        </a>
                        
                    </div>

                </div>

            </div>

        </div>

    </div>

</template>

<script>
import SectionTitle from './blocks/SectionTitle.vue'

import emailjs from 'emailjs-com';
import{ init } from 'emailjs-com';

export default {
    components: {
        SectionTitle
    },
    data() {
        return {
            name: '',
            email: '',
            phone_number: '',
            message: ''
        }
    },
    methods: {
        sendEmail() {
            init("user_tGUGiijgZDY1nq2KNk1WS");

            try {
                emailjs.send("service_15smwfh","template_xj0xvrl",{
                from_name: this.name,
                phone_number: this.phone_number,
                message: this.message,
                reply_to: this.email,
            })
            } catch(error) {
                console.log({error})
            }

            document.getElementById("button").style.display = 'none'
            document.getElementById("messageSent").style.display = 'block'

            var form = document.getElementById('contactForm')
            var elements = form.elements
        
            for (var i = 0; i < elements.length; i++) {
                elements[i].readOnly = true
            }
        }
  }
}
</script>

<style>
#Contact .body-content{
    padding-top: 168px;
    padding-bottom: 168px;
}

#Contact .title-group{
    margin-bottom: 40px;
}

.columns{
    display: flex;
    gap: 80px;
}

.column img{
    width: 100%;
    height: auto;

    max-width: 503px;
}

#form{
    max-width: 567px;
}

.form{
    margin-top: 32px;
}

#contactForm{
    display: flex;
    flex-direction: column;
}

label{
    font-size: 16px;
    
    margin: 0px 0px 4px 8px;
}

input{
    height: 48px;
    margin-bottom: 16px;

    background-color: #FEFEFE;
    border: 1px solid #E8E8E8;

    padding: 0 16px;

    font-family: "futura-pt", sans-serif;
    font-weight: 400;
    font-size: 16px;
    color: #4A4446;
}

input:read-only{
    background-color: #F9F9F9;
}

input#button{
    font-family: "futura-pt", sans-serif;
    letter-spacing: 0.6px;
    font-weight: 400;
    font-size: 20px;

    height: 48px;
    width: 370px;

    border-radius: 24px;
    border-color: #EE3965;
    border-style: solid;

    background-color: white;
    color: #EE3965;

    cursor: pointer;
    transition: ease-out 200ms;
}

input#button:hover{
    background-color: #EE3965;
    color: white;
}

#messageSent{
    display: none;
}

textarea{
    height: 160px;

    margin-bottom: 32px;

    background-color: #FEFEFE;

    border: 1px solid #E8E8E8;

    padding: 16px;

    font-family: "futura-pt", sans-serif;
    font-weight: 400;
    font-size: 16px;
    color: #4A4446;
}

textarea:read-only{
    background-color: #F9F9F9;
}

.map-card{
    padding: 40px 32px 32px 32px;

    background: #FFF;
    box-shadow: 0px 2px 12px 3px rgba(35, 5, 13, 0.06);
}

.map-card p{
    font-weight: 600;

    margin-bottom: 24px;
}

@media screen and (max-width: 600px){

    #Contact .body-content{
        padding-top: 64px;
        padding-bottom: 96px;
    }

    #Contact .title-group{
        margin-bottom: 24px;
    }

    .columns{
        flex-direction: column;
        gap: 64px;
    }

    #form{
        width: 100%;
    }

    .form{
        margin-top: 24px;
    }

    label{
        font-size: 13px;
        
        margin: 0px 0px 2px 0px;
    }

    input{
        height: 40px;
    }

    textarea{
        height: 120px;
    }

    input#button{
        width: 100%;
    }

    .map-card{
        padding: 16px 24px 24px 32px;

        background: #FFF;
        box-shadow: 0px 2px 12px 3px rgba(35, 5, 13, 0.06);
    }

    .map-card p{
        margin-bottom: 16px;
    }

}
</style>