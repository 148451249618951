<template>
    <div class="section" id="Tarieven">

        <div class="body-content">
            
            <SectionTitle text="Tarieven"/>

            <p>Ik ben geconventioneerd en hanteer dus de tarieven die het <a href="https://www.riziv.fgov.be/nl" target="blank">RIZIV</a> vooropgesteld heeft.</p>

            <TariefTable/>

            <p>Wanneer u niet in aanmerking komt om terugbetaling te krijgen via de verplichte verzekering, dan kan u gebruik maken van uw <strong>aanvullende verzekering</strong>. U kan het bedrag van terugbetaling vinden op de website van uw mutualiteit.</p>

        </div>

    </div>
</template>

<script>
import SectionTitle from './blocks/SectionTitle.vue'
import TariefTable from './blocks/Tarief-Table.vue'

export default {
    components: {
        SectionTitle,
        TariefTable
  }
}
</script>

<style>
#Tarieven .body-content{
    padding-top: 168px;
    padding-bottom: 80px;
}

#Tarieven .title-group{
    margin-bottom: 64px;
}

#Tarieven p{
    max-width: 1158px;
}

.table{
    margin: 48px 0;
}

@media screen and (max-width: 600px){

    #Tarieven .body-content{
        padding-top: 48px;
        padding-bottom: 120px;
    }

    #Tarieven .title-group{
        margin-bottom: 24px;
    }

    .table{
        margin: 24px 0px;
    }

}

</style>