<template>
    <div class="step">
        <div class="counter">
            <strong>{{ count }}</strong>
        </div>
        <div class="step-text">
            <p class="step-title">{{ title }}</p>
            <slot></slot>
        </div>
    </div>
</template>

<script>
export default {
    props: ['count', 'title']
}
</script>

<style>
.step{
    display: flex;
    gap: 24px;
}

.counter{
    display: flex;

    align-items: center;
    justify-content: center;

    height: 32px;
    min-width: 32px;

    border-radius: 100%;

    background-color: #EE3965;

    color: white;
    font-size: 16px;
}

.step-text{
    width: 884px;

    display: flex;
    flex-direction: column;
    gap: 16px;
}

.step-title{
    font-weight: 600;
    color: #201D1E;
}

@media screen and (max-width: 600px){

    .step{
        gap: 16px;
    }

    .step-text p{
        margin-left: -48px;
    }

    .step-text p.step-title{
        margin-left: 0px;
    }

}
</style>