<template>
    <a href="#Behandelingen">

        <div class="treatment-wrapper">

            <div class="treatment">

                {{ text }}

                <img src="../../assets/Chevron-Treatment.svg">

            </div>

        </div>
    </a>
    
</template>

<script>
export default {
    props: ['text']
}
</script>

<style>
.treatment-wrapper{
    display: inline-block;
}

.treatment{
    display: flex;
    align-items: center;
    gap: 16px;

    padding: 12px 24px;

    background-color: #FCF8F9;

    font-size: 16px;
    font-weight: 500;
    letter-spacing: 0.48px;
}

a .treatment{
    color: #201D1E;
}

@media screen and (max-width: 600px){
    .treatment{
        padding: 9px 12px;
    }
}
</style>