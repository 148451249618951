<template>
    <div class="behandeling">

        <div class="title-field">

            <p>{{ title }}</p>
            
            <div class="chevron-container">
                <img src="../../assets/Chevron-Treatment.svg">
            </div>

        </div>
      
        <slot></slot>

    </div>
</template>

<script>
export default {
    props: ['title']
}
</script>

<style>
.behandeling{
    width: 961px;

    border-left: 0px solid #F373A9;

    padding-left: 40px;

    background-color: white;

    box-shadow: 0px 4px 6px 3px rgba(35, 5, 13, 0.02);

    transition: ease-out 200ms;

    cursor: pointer;
}

.behandeling.active{
    border-left: 12px solid #F373A9;

    padding-bottom: 64px;
    padding-left: 28px;
}

.title-field{
    position: relative;
    padding: 32px 0px 32px 0px;

    color: #201D1E;
    font-weight: 600;
    font-size: 25px;
}

.chevron-container{
    position: absolute;
    right: 0;
    top: 0;

    display: flex;
    align-items: center;
    justify-content: center;

    background-color: #FEF5F8;

    height: 100%;
    width: 104px;
}

.chevron-container img{
    width: 44px;
    height: 44px;

    transform: rotate(90deg);

    transition: ease-out 200ms;
}

.behandeling.active .chevron-container img{
    transform: rotate(180deg);
}

.behandeling-description{
    display: none;
    flex-direction: column;
    gap: 24px;

    margin-top: 48px;

    width: 723px;
}

#neurogene .behandeling-description{
    gap: 48px;
}

.active .behandeling-description{
    display: flex;
}

.behandeling-group p{
    margin-bottom: 24px;
}

.behandeling-group p:first-child{
    margin-bottom: 8px;
}

.behandeling-group p:last-child{
    margin-bottom: 0px;
}

@media screen and (max-width: 600px){

    .behandeling{
        padding-left: 24px;

        width: auto;
    }

    .behandeling.active{
        padding-left: 12px;
        padding-bottom: 48px;
    }

    .title-field{
        padding: 16px 0px;
    }

    .title-field p{
        font-size: 16px;

        width: calc(100% - 80px);
    }

    .chevron-container{
        width: 64px;
    }

    .behandeling-description{
        width: auto;

        margin-right: 24px;
        margin-top: 24px;
    }

    .behandeling-description p{
        font-size: 16px;
    }

    #neurogene .behandeling-description{
    gap: 32px;
}

}
</style>