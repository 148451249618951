<template>
  <div class="title-group">
    <h1>{{ text }}</h1>
    <img src="../../assets/Underline-Title.svg">
  </div>
  
</template>

<script>
export default {
  props: ['text']
}
</script>

<style>
.title-group{
  position: relative;
}

.title-group img{
  position: relative;
  left: -2px;
  margin-top: 8px;
}

@media screen and (max-width: 600px){

  .title-group img{
    height: 4px;

    margin-top: 2px;
  }

}
</style>